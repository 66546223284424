<template>
  <div class="d-flex justify-content-center">
    <b-card class="content-card">
      <p>
        To be added ...
      </p>
    </b-card>
  </div>
</template>
<script>
import {
  BCard,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
  },
}
</script>

<style scoped>
.content-card {
  width: 75%
}

@media screen and (max-width: 1024px) {
  .content-card {
    width: 100%;
  }
}
</style>
